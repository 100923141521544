<template>
    <v-img width="300px" height="150px" :src="imageURL">
        <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
            </v-row>
        </template>
    </v-img>
</template>

<script>
import { mapMutations } from 'vuex'
import { loadImage } from '@/helpers/imageHandler.js'

export default {
    name: 'HeaderImage',
    props: {
        image: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        imageURL: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        folderPdfImage: 'pdf_images',
    }),
    async mounted() {
        try {
            const path = `${this.companyId}/${this.folderPdfImage}`
            const nameImage = this.image.file.split('.')
            this.image.imageURL = await loadImage(
                path,
                nameImage[0] + '_320x320.' + nameImage[1]
            )
            this.imageURL = this.image.imageURL
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
    },
}
</script>

<style></style>
